import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

const Auth = () => {
  const [loading, setLoading] = useState(false);

  const [origin, setOrigin] = useState("");

  useEffect(() => {
    // Determine the origin based on the current hostname
    const hostname = window.location.hostname;
    if (hostname === "checkmycharts.com") {
      setOrigin("checkmycharts");
    } else if (hostname === "cases.myafterimage.com") {
      setOrigin("myafterimage");
    } else {
      setOrigin("unknown");
    }
  }, []);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${window.location}/callback`,
        },
      });
      if (error) throw error;
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-300 to-indigo-400">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-2xl">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-black">
            Welcome to QuickChron
          </h1>
          <p className="mt-2 text-lg text-gray-600">
            You're one step away from finding out if your doctor met the
            standard of care.
          </p>
        </div>
        <div className="space-y-4">
          <p className="text-sm text-gray-600">
            By signing in, you'll get access to:
          </p>
          <ul className="list-disc list-inside text-sm text-gray-600 space-y-2">
            <li>AI-powered evidence detection</li>
            <li>Same-day record analysis reports</li>
            <li>Bank-level data encryption and privacy</li>
          </ul>
        </div>
        <div className="space-y-4">
          <button
            onClick={handleLogin}
            disabled={loading}
            className="w-full flex items-center justify-center px-4 py-2 space-x-4 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
          >
            {loading ? (
              <svg
                className="w-5 h-5 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
              </svg>
            )}
            <span>
              {loading ? "Signing in..." : "Start Your Free Trial with Google"}
            </span>
          </button>
          <p className="text-xs text-center text-gray-500">
            This portal is HIPAA compliant. Your data is always
            protected.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Auth;
