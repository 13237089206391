import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { X, CheckCircle2, XCircle } from "lucide-react";
import FeatureHighlights from "./FeatureHighlights";

const FeatureItem = ({ icon: Icon, text, onClick }) => (
  <div className="flex items-center space-x-3 cursor-pointer" onClick={onClick}>
    <Icon className="w-6 h-6 text-blue-500" />
    <span className="text-gray-700">{text}</span>
  </div>
);

const Modal = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-11/12">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        <p className="text-gray-700">{content}</p>
      </div>
    </div>
  );
};

const ComparisonBubble = ({ color, label, points, isPositive }) => (
  <div
    className={`${color} p-6 rounded-2xl shadow-lg flex flex-col justify-center items-stretch w-full sm:w-72 h-auto sm:h-72 mb-4 sm:mb-0`}
  >
    <h3 className="text-xl font-semibold mb-4 text-center">{label}</h3>
    {points.map((point, index) => (
      <div key={index} className="flex items-center mb-3 last:mb-0">
        {isPositive ? (
          <CheckCircle2 className="mr-3 h-6 w-6 flex-shrink-0 text-green-600" />
        ) : (
          <XCircle className="mr-3 h-6 w-6 flex-shrink-0 text-red-600" />
        )}
        <span
          className={`text-sm leading-tight ${isPositive ? "text-green-800" : "text-red-800"}`}
        >
          {point}
        </span>
      </div>
    ))}
  </div>
);

const FAQItem = ({ question, answer }) => (
  <div className="mb-6">
    <h3 className="text-xl font-semibold mb-2 text-blue-800">{question}</h3>
    <p
      className="text-gray-700 faq-content"
      dangerouslySetInnerHTML={{ __html: answer }}
    />
  </div>
);

const LandingPage = ({ session }) => {
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(null);

  const handleButtonClick = () => {
    if (session) {
      navigate("./cases");
    } else {
      navigate("./auth");
    }
  };

  return (
    <div className="w-screen mx-auto min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-100">
      <div className="container mx-auto px-4 pb-10 pt-5 sm:py-10">
        <div className="max-w-3xl mx-auto text-center mt-8 mb-8 sm:mb-8">
          <h1 className="text-4xl sm:text-6xl font-bold mb-4 sm:mb-6 text-gray-800 leading-tight">
            Check if Your Case
            <br />
            is Viable in Minutes
          </h1>
          <p className="text-xl sm:text-3xl text-slate-700 mb-6 sm:mb-10">
            Upload medical records and get evidence now.
          </p>
        </div>
        <div className="my-10 flex flex-col items-center">
          <button
            onClick={handleButtonClick}
            className="w-8/12 bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 rounded-lg text-center transition duration-300 ease-in-out text-lg"
          >
            {session ? "Go to Cases" : "Try now for free"}
          </button>
        </div>
        <FeatureHighlights />

        <div className="mb-10">
          <h2 className="text-4xl font-semibold my-10 text-center text-gray-800">
            How QuickChron Compares
          </h2>
          <div className="flex flex-col sm:flex-row justify-center items-stretch sm:items-center gap-4 sm:gap-8 py-4 px-4 sm:px-0">
            <ComparisonBubble
              color="bg-green-100"
              label="QuickChron"
              points={[
                "Done in Minutes",
                "Investigates Every Page",
                "Designed to Help Lawyers Screen Cases Fast",
              ]}
              isPositive={true}
            />
            <ComparisonBubble
              color="bg-yellow-100"
              label='"AI Medical Chronology"'
              points={[
                "Outputs Fake Medical Data",
                "Skips Pages and Evidence",
                "Sends Records to ChatGPT",
              ]}
              isPositive={false}
            />
            <ComparisonBubble
              color="bg-red-100"
              label="Manual Review"
              points={[
                "Days of Tedious Work",
                "Human Exhaustion and Error",
                "Unreliable Medical Knowledge",
              ]}
              isPositive={false}
            />
          </div>
        </div>

        {/*<div className="text-center mb-10">
          <h2 className="text-3xl font-semibold mb-6 text-blue-800">
            Why Afterimage Excels
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            While humans and ChatGPT may overlook crucial details buried in
            hundreds of pages, Afterimage meticulously examines every page.
            <br />
            Our AI is trained to spot the subtle indicators that often require
            deep medical expertise to recognize.
          </p>
          <p className="text-xl font-bold text-blue-600">
            "We don't just scan, we scrutinize — catching what others miss."
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-xl p-10 mb-20">
          <h2 className="text-3xl font-semibold mb-6 text-center text-blue-800">
            Hear from Our Users
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <blockquote className="italic border-l-4 border-blue-500 pl-4 text-gray-700">
              "Afterimage found evidence of a pre-existing condition for a case
              I would've dumped a week of time into investigating because the
              damages seemed so high. In just an hour, it found the needle in
              the haystack that saved me and my paralegals from wasting so many
              hours on a dead-end case."
              <footer className="mt-2 font-semibold">
                - Sarah P., Plaintiff's Attorney
              </footer>
            </blockquote>
            <blockquote className="italic border-l-4 border-blue-500 pl-4 text-gray-700">
              "We had a doctor tell us he checked his notes and did everything
              right. Then we fed the plaintiff's health records into Afterimage
              and guess who forgot to tell the patient her CT results... saved
              us a lot of time."
              <footer className="mt-2 font-semibold">
                - Donald S., Medical Malpractice Defense Attorney
              </footer>
            </blockquote>
          </div>
        </div>*/}

        <div className="container mx-auto px-4">
          <div className="bg-white rounded-xl shadow-xl p-10 mb-20">
            <h2 className="text-3xl font-semibold mb-8 text-center text-blue-800">
              Frequently Asked Questions
            </h2>
            <FAQItem
              question="Do we need patient authorization?"
              answer="We provide you with a standard HHS approved HIPAA Business Associates Agreement and assume responsibility for complying with HIPAA. Our engineers are formally HIPAA certified."
            />
            <FAQItem
              question="Is it like tools that let you 'chat with your docs'?"
              answer="Those services simply send all your data to ChatGPT and give you the output. We experienced first-hand how unreliable its answers are and how often it hallucinates false information based on what we asked. Our evidence engine uses specialized algorithms that take the accuracy rate from 41% to 92% in experiments on a dataset of anonymized medical records. In short, it isn't a simple chatbot, it's an evidence-gathering system."
            />
            <FAQItem
              question="Is it a medical chronology tool?"
              answer="No. There are 100 services that use ChatGPT to make what they call 'AI medical chronologies.' Instead, we get straight to the point and give you what you need to decide if a case is worth pursuing fast: pre-existing conditions, missed diagnoses, missing treatment options, and so forth. If you want, our automation will make a chronology for you that you can export into any case management system for free. For later stages of litigation with substantial budgets, we recommend EvenUp for chronologies reviewed by humans."
            />
            <FAQItem
              question="Who are you?"
              answer={`Our chief legal advisor Joe Choe earned his J.D. from Harvard Law School and works as a litigator at <a href="https://skadden.com/">Skadden</a>. Our chief engineer Andrew Gregory was senior AI scientist at (<a href="https://www.roivant.com/">Roivant Sciences</a>) before building QuickChron. This all started when we helped a friend pursue a medical malpractice case and secured him $3MM in compensation. Now we want to make it easier for more people to get justice by minimizing the time and money it takes to decide if a case is worth it. We'll work with you to make sure you never waste time on a bad case and guarantee you catch the good ones.`}
            />
          </div>
        </div>

        <div className="text-center mt-8 sm:mt-10">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-blue-800">
            Ready to Uncover the Truth?
          </h2>
          <button
            onClick={handleButtonClick}
            className="bg-blue-600 text-white px-8 sm:px-12 py-3 sm:py-4 rounded-md text-lg sm:text-xl font-semibold hover:bg-blue-700 transition duration-300 shadow-lg w-full sm:w-auto"
          >
            {session ? "Go to Cases" : "Start Your Free Trial Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
